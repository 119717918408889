@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap');
@use postcss-nested;

@font-face {
  font-family:Worksans-bold ;
  src: url(./fonts/WorkSans-Bold.ttf);
  
}
@font-face {
  font-family:WorkSans-Regular ;
  src: url(./fonts/WorkSans-Regular.ttf);
}
@font-face {
  font-family: WorkSans-Semibold;
  src: url(./fonts/WorkSans-SemiBold.ttf);
}
@font-face {
  font-family: WorkSans-Medium;
  src: url(./fonts/WorkSans-Medium.ttf);
}
.center{
    left: 100%;
    transform: translateX(11.5%);
}

.inner-image {
    position: absolute;
    top: 77px;
    right: 163px;
}

.bg-gray-blur{
  border-radius: 19px;
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  box-shadow: 0 3px 39px -7px rgba(0, 0, 0, 0.22);
  background-color: rgba(45, 57, 64, 0.56);
}

.no-blur{
  filter:blur(0px);
}

.home-grid-color{
  background-color: rgba(45, 56, 64, 0.35);
  border-radius:18px;
}

.dark-gray-color{
  background-color: rgba(45, 56, 64, 0.35);
}

#switch-collapse:checked + #collapse-target {
  display: block;
}

#collapse-target {
  display: none;
}

.column {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.Vector {
  width: 853.8px;
  height: 389.4px;
  margin: 321.7px 280.6px 0 305.6px;
  transform: rotate(-296.32deg);
  opacity: 0.5;
  -webkit-filter: blur(200px);
  filter: blur(200px);
  background-color: #f8f000;
}


.check-svg {
  animation: svgScale 0.5s ease-in-out;
}
.download-arrow {
  transition: transform 0.2s linear;
}
button:hover .download-arrow {
  transform: translate(0, 3px);
}

@keyframes svgScale {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }
}

/* your-styles.css */
.react-pdf-viewer__viewer {
  height: 500px; /* Set the desired height */
  max-width: 100%;
  margin: auto;
}

.react-pdf-viewer__canvas {
  max-width: 100%;
  margin: auto;
}




