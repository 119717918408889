.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.work-sans {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400; /* Normal */
}

.work-sans-semibold {
  font-family: 'Work Sans', sans-serif;
  font-weight: 600; /* Semi-bold */
}

.work-sans-bold {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700; /* Bold */
}

.work-sans-extrabold {
  font-family: 'Work Sans', sans-serif;
  font-weight: 800; /* Extra-bold */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.slick-dots {
  margin-bottom: 56% !important;
  right: 42%;
  
}
.slick-dots li button:before {
  color: white !important;
}
.slick-dots li.slick-active button:before {
  color: white !important;
}

.slick-arrow,
.slick-next,
.slick-disabled {
    margin-right: 5%;
    
}


.slick-arrow,
.slick-prev,
.slick-disabled {
  margin-left: 96%;
  z-index: 1;
  margin-top: -6%;  
}


