@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100&display=swap');

.landing-font{
   font-family: "Work Sans", serif;
}

 .portfolio-bg {
  background-image: linear-gradient(142deg, #f7ee00 16%, #cac41f 97%);
}

.white-gradient {
   background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
   opacity: 0.9;
   z-index: 0;
}

.dark-background-color{
   background-image: radial-gradient(circle at 52% 88%, #404958, #272b31 65%);
}

.button-bg{
  background-color: rgba(176, 186, 201, 0.24);
}


.arrow-down-left {
  border-bottom: 0 solid transparent;
  border-left: 15px solid black;
  border-top: 15px solid transparent;
}

.flag-bg{
   background-color: rgba(51, 55, 60, 0.04);
}

/* .gradient-animation-right{
   width: 300px;
   height: 300px;
   border-radius: 10px;
   position: relative;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
}

.gradient-animation-right::before{
   content: "";
   background-image: conic-gradient(from 180deg, yellow, transparent );
   width: 900%;
   height: 900%;
   position: absolute;
   animation: rotate 3s linear;
   animation-iteration-count: 3;
}

.gradient-animation-right::after{
   border:200px;
   border-color:red;
   width: 300px;
   height: 300px;
   background: #0A0A0A;
   position: absolute;
   border-radius: 10px;
   display: flex;
   font-size: larger;
   letter-spacing: 5px;
}

.gradient-animation-right-2{
   width: 295px;
   height: 295px;
   background: #161c24;
   position: absolute;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   font-size: larger;
   letter-spacing: 5px;
}

@keyframes rotate {
   from {transform: rotate(0deg);}
   to {transform: rotate(-360deg);}
 }

 .gradient-animation-left{
   width: 300px;
   height: 300px;
   border-radius: 10px;
   position: relative;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
}

.gradient-animation-left::before{
   content: "";
   background-image: conic-gradient(from 180deg, yellow, transparent );
   width: 900%;
   height: 900%;
   position: absolute;
   animation: rotate 3s linear;
   animation-iteration-count: 3;
}

.gradient-animation-left::after{
   border:200px;
   border-color:red;
   width: 300px;
   height: 300px;
   background: #0A0A0A;
   position: absolute;
   border-radius: 10px;
   display: flex;
   font-size: larger;
   letter-spacing: 5px;
}

.gradient-animation-left-2{
   width: 295px;
   height: 295px;
   background: #161c24;
   position: absolute;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   font-size: larger;
   letter-spacing: 5px;
}

@keyframes rotate {
   from {transform: rotate(0deg);}
   to {transform: rotate(-360deg);}
 }

 @media (min-width: 768px) {
   .gradient-animation-left{
      width: 300px;
      height: 300px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
   }
   
   .gradient-animation-left::before{
      content: "";
      background-image: conic-gradient(from 60deg, yellow, transparent );
      width: 900%;
      height: 900%;
      position: absolute;
      animation: rotate 3s linear;
      animation-iteration-count: 3;
   }
   
   .gradient-animation-left::after{
      border:200px;
      border-color:red;
      width: 300px;
      height: 300px;
      background: #0A0A0A;
      position: absolute;
      border-radius: 10px;
      display: flex;
      font-size: larger;
      letter-spacing: 5px;
   }
   
   .gradient-animation-left-2{
      width: 295px;
      height: 295px;
      background: #161c24;
      position: absolute;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: larger;
      letter-spacing: 5px;
   }
   
   @keyframes rotate {
      from {transform: rotate(0deg);}
      to {transform: rotate(-360deg);}
    }

    .gradient-animation-right{
      width: 300px;
      height: 300px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
   }
   
   .gradient-animation-right::before{
      content: "";
      background-image: conic-gradient(from -60deg, yellow, transparent );
      width: 900%;
      height: 900%;
      position: absolute;
      animation: rotate 3s linear;
      animation-iteration-count: 3;
   }
   
   .gradient-animation-right::after{
      border:200px;
      border-color:red;
      width: 300px;
      height: 300px;
      background: #0A0A0A;
      position: absolute;
      border-radius: 10px;
      display: flex;
      font-size: larger;
      letter-spacing: 5px;
   }
   
   .gradient-animation-right-2{
      width: 295px;
      height: 295px;
      background: #161c24;
      position: absolute;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: larger;
      letter-spacing: 5px;
   }
   
   @keyframes rotate {
      from {transform: rotate(0deg);}
      to {transform: rotate(-360deg);}
    } 
 }

 .border-bottom{
   border-bottom:solid;
 } */