.hideMenuNav {
    display: none;
  }
  .showMenuNav {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    border-radius:0px;
    background: #161c24;;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    z-index: 10;
    border-radius:2px;
    margin-top:30px;
    margin-left:50px;
    border-radius:1px;
    text-align:center;
    cursor:pointer;
    border-radius:16px;
    border: 0.5px solid #eae7e7;

  }
 
  .dropdown-button{
    background-color:transparent;
    cursor:pointer;
  }

  .dropdown-content-language{
    position: absolute;
    background-color: white;
    width: 137px;
    padding: 8px;
    flex-direction: column;
    z-index: 10;
    margin-top:10px;
    margin-left:-10%; 
    border-radius: 16px;
    cursor:pointer;
    border: 0.5px solid #eae7e7;
    }
  .dropdown-button-language{
    background-color:transparent;
    cursor:pointer;
  }

  .light-gray-bg{
    background-color:rgba(145, 158, 171, 0.16);
  }